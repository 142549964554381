export const MEMBERSHIP_TIERS = ['BASIC', 'HACKSTER', 'PARTNER', 'PENDING', 'PRO', 'VERIFIED'];

export const MEMBERSHIP_TIERS_RANK_MAP = {
  BASIC: 1,
  PENDING: 2,
  VERIFIED: 3,
  PRO: 4,
  PARTNER: 5,
  HACKSTER: 6,
};
