import request from 'superagent';
import OAuth from '../services/oauth'; 

export const fetchImageFromUrl = (remoteURL) => {
  return new Promise((resolve, reject) => {
    OAuth.getApiToken((token) => {
      fetch(`${OAuth.getApiPath()}/v2/images`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ remote_url: remoteURL })
      })
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  });
}