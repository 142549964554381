/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cropper from './cropper';
import Dialog from '../../reusable_components/Dialog';
import DropZone from '../../wrappers/drop_zone';
import Prompt from '../../reusable_components/Dialog/Prompt';

import errorHandler from '../../../services/error_handler';

import { canvasToBlob, promisifiedFileReader } from '../../utils/Images';
import { getAttachmentTypeEnum } from '../../../graphql/attachments/enums';
import { fetchImageFromUrl } from '../../../requests/images';
import { getErrorMsg } from '../../global_components/messenger/messages';
import { getInObj } from '../../../utility/accessors';
import { getFilesFromEvent } from '../../../utility/events';
import { getFileNameFromUrl } from '../../../utility/links';
import { isUrlWithProtocol, isValidImageFile } from '../../../services/validation/validators';
import {
  JPEG_MIMETYPE,
  isGif,
  isJpeg,
  getAndUpdateAttachmentFromLocalFile,
  getAndUpdateAttachmentFromRemoteURL,
  getImageDimsFromUrl,
  getInputAcceptProp,
} from '../../../utility/images';

const INITIAL_STATE = {
  cropperData: {},
  isBusy: false,
  remoteURL: '',
  showCropper: false,
  showDeletePrompt: false,
};
const JPEG_QUALITY = 0.85;

class UploaderWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.handleCropperError = this.handleCropperError.bind(this);
    this.handleFileEvent = this.handleFileEvent.bind(this);
    this.handleImageCrop = this.handleImageCrop.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleUploadBtnClick = this.handleUploadBtnClick.bind(this);
    this.handleURLChange = this.handleURLChange.bind(this);
    this.handleURLSubmit = this.handleURLSubmit.bind(this);

    // refs
    this._fileInput;
  }

  /**
   * Methods
   */
  handleFileEvent(e) {
    e.preventDefault();

    const file = getFilesFromEvent(e)[0];
    if (this._fileInput) this._fileInput.value = '';

    const msg = isValidImageFile(file, this.props.allowGifs);
    if (msg) return this._reportError({ msg });

    this.handleReset({ isBusy: true });
    this._processFile(file);
  }

  handleImageCrop(canvasElement) {
    const { fileName, fileType } = this.state.cropperData; // grab state data before resetting

    this.handleReset({ isBusy: true });
    this._processCroppedImage(canvasElement, fileName, fileType);
  }

  handleReset(overrides = {}) {
    const newState = { ...INITIAL_STATE, ...overrides };

    if (newState.isBusy !== this.state.isBusy) {
      this.props.propagateStatus(newState.isBusy); // update busy state if needed
    }
    this.props.reportError(null); // clear any errors
    this.props.propagateUpload({}); // clear imageData

    this.setState(newState);
  }

  handleCropperError(err) {
    this.setState({ cropperData: {}, showCropper: false });
    this._reportError(err);
  }

  handleUploadBtnClick(e) {
    if (this._fileInput) {
      e.preventDefault();
      this._fileInput.click();
    }
  }

  handleURLChange(e) {
    this.setState({ remoteURL: e.target.value });
  }

  handleURLSubmit() {
    const remoteURL = this.state.remoteURL;

    const msg = isUrlWithProtocol(remoteURL);
    if (msg) return this._reportError({ msg });

    this.handleReset({ isBusy: true });
    this._processRemoteURL(remoteURL);
  }

  /**
   * Helpers
   */
  _getImageAttachmentArgs() {
    return {
      get_image_version: this.props.imageVersion,
      type: getAttachmentTypeEnum(this.props.attachmentType),
    };
  }

  _getViewProps() {
    return {
      ...this.props,
      handleDelete: () => this.setState({ showDeletePrompt: true }),
      handleURLChange: this.handleURLChange,
      handleURLSubmit: this.handleURLSubmit,
      handleUploadBtnClick: this.handleUploadBtnClick,
      isBusy: this.state.isBusy,
      remoteURL: this.state.remoteURL,
    };
  }

  async _processCroppedImage(canvasElement, name, type) {
    try {
      // Encoding to jpeg results in much smaller file size. Do it if the original image was a jpeg.
      const options = isJpeg(type) ? [JPEG_MIMETYPE, JPEG_QUALITY] : [];
      const { height, width } = canvasElement;

      const blob = await canvasToBlob(canvasElement, options);
      blob.name = name;

      const qlData = await getAndUpdateAttachmentFromLocalFile(blob, { height, width }, this._getImageAttachmentArgs());

      this._resolveUploadImage(qlData, name);
    } catch (err) {
      this._reportError(err);
    }
  }

  async _processFile(file) {
    try {
      // TODO look into using URL.createObjectURL() instead of FileReader to save time and memory
      const { dataUrl } = await promisifiedFileReader(file);

      if (this._shouldOpenCropper(file.type)) return this._seedCropper(file, dataUrl);

      const dimensions = await getImageDimsFromUrl(dataUrl);
      const qlData = await getAndUpdateAttachmentFromLocalFile(file, dimensions, this._getImageAttachmentArgs());

      this._resolveUploadImage(qlData, file.name);
    } catch (err) {
      this._reportError(err);
    }
  }

  async _processRemoteURL(remoteURL) {
    try {
      const response = await fetchImageFromUrl(remoteURL)
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const type = blob.type;
      const msg = isValidImageFile({ type }, this.props.allowGifs);
      if (msg) return this._reportError({ msg });

      const name = getFileNameFromUrl(remoteURL);
      if (this._shouldOpenCropper(type)) return this._seedCropper({ name, type }, url);

      const qlData = await getAndUpdateAttachmentFromRemoteURL(url, this._getImageAttachmentArgs());
      this._resolveUploadImage(qlData, name);
    } catch (err) {
      this._reportError(err);
    }
  }

  _reportError(errorObj = {}) { // can either be an Error object or an object of shape {err<Error>, msg<string>}
    const msg = errorObj.hasOwnProperty('msg') ? errorObj.msg : getErrorMsg('uploading your image');
    const err = errorObj.hasOwnProperty('err') ? errorObj.err : (errorObj instanceof Error) ? errorObj : null;

    this.props.reportError(msg);
    if (err) errorHandler(err);

    this.setState({ isBusy: false });
    this.props.propagateStatus(false);
  }

  _reportUpload({ dimensions = {}, id, name, url }) {
    this.props.propagateUpload({ dimensions, id, name, url });
    this.props.propagateStatus(false);
    this.setState(INITIAL_STATE);
  }

  _resolveUploadImage(qlData, name) {
    if (!getInObj(['updateAttachment', this.props.attachmentURLKey], qlData)) {
      return this._reportError(new Error('updateAttachmentMutation failed! Check the request.'));
    }

    const id = parseInt(qlData.updateAttachment.id);
    const dimensions = qlData.updateAttachment.metadata;
    const url = qlData.updateAttachment[this.props.attachmentURLKey];

    this._reportUpload({ dimensions, id, name, url });
  }

  _seedCropper(file, url) {
    this.setState({
      cropperData: {
        src: url,
        fileName: file.name ? file.name : 'tmp_image_0',
        fileType: file.type,
      },
      showCropper: true,
    });
  }

  _shouldOpenCropper(mimeType) {
    return !(this.props.ignoreCropper || isGif(mimeType));
  }

  render() {
    return (
      <DropZone disabled={this.state.isBusy} onDrop={this.handleFileEvent}>
        {' '}
        {/* TODO: visual dragover indication - ask designer? */}
        <input
          ref={(el) => this._fileInput = el}
          accept={getInputAcceptProp(this.props.allowGifs)}
          className={this.props.inputClassName}
          onChange={this.handleFileEvent}
          style={{ display: 'none' }}
          type="file"
        />
        {this.props.renderView(this._getViewProps())}
        {!this.props.ignoreCropper
        && (
          <Dialog
            dismiss={this.handleReset}
            nestedDialogLevel={this.props.nestedDialogLevel}
            open={this.state.showCropper}
            title={this.props.dialogTitle}
          >
            {this.state.showCropper
            && (
              <Cropper
                aspectRatio={this.props.aspectRatio}
                handleImageCrop={this.handleImageCrop}
                onCancel={this.handleReset}
                onError={this.handleCropperError}
                src={this.state.cropperData.src}
              />
            )}
          </Dialog>
        )}
        <Prompt
          action="Remove"
          actionColor="danger"
          dismiss={() => this.setState({ showDeletePrompt: false })}
          okay={this.handleReset}
          open={this.state.showDeletePrompt}
          title="Are you sure you want to remove this image?"
        />
      </DropZone>
    );
  }
}

UploaderWrapper.propTypes = {
  allowGifs: PropTypes.bool,
  aspectRatio: PropTypes.number,
  attachmentType: PropTypes.string,
  attachmentURLKey: PropTypes.oneOf(['file_url', 'imgix_url']),
  cropperTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  dimensionsMins: PropTypes.shape({ width: PropTypes.number }),
  ignoreCropper: PropTypes.bool,
  imageData: PropTypes.shape({
    dimensions: PropTypes.shape({ width: PropTypes.number }),
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  imageVersion: PropTypes.string, // Maps to Image.rb VERSIONS (or Image subclass specified by attachmentType)
  inputClassName: PropTypes.string, // if outside code (e.g. project editor) is looking for form element changes, setting this className can serve as a flag for that code to ignore all input elements in this component, since a changing them does not necessarily mean a change in the uploaded image
  nestedDlogLevel: PropTypes.number,
  propagateStatus: PropTypes.func,
  propagateUpload: PropTypes.func.isRequired,
  renderView: PropTypes.func.isRequired,
  reportError: PropTypes.func,
};

UploaderWrapper.defaultProps = {
  allowGifs: true,
  aspectRatio: null,
  attachmentType: 'Image',
  attachmentURLKey: 'imgix_url',
  ignoreCropper: false,
  imageData: {},
  imageVersion: null,
  inputClassName: '',
  nestedDialogLevel: 0,
  propagateStatus: () => {},
  reportError: () => {},
};

export default UploaderWrapper;
